











































































































import Component, {mixins} from 'vue-class-component';
import { placeModule, reviewModule, reservationModule } from '@/store';
import dataFormat from '@/shared/utils/dataFormat';
import { EventBus } from '@/config/config';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { filter } from 'lodash';
import * as firebase from 'firebase/app';

@Component<Place>({
  components: {
    'thumbnail-list': () => import('@/components/common/ThumbnailList.vue'),
    'main-info': () => import('@/components/place/MainInfo.vue'),
    'product-description': () => import('@/components/product/ProductDescription.vue'),
    'product-config': () => import('@/components/product/ProductConfig.vue'),
    'reservation-info': () => import('@/components/product/ReservationInfo.vue'),
    'reservation-notice': () => import('@/components/product/ReservationNotice.vue'),
    'place-info': () => import('@/components/common/PlaceInfo.vue'),
    'place-notice': () => import('@/components/common/PlaceNotice.vue'),
    'use-info': () => import('@/components/place/UseInfo.vue'),
    'ticket-option': () => import('@/components/place/TicketOption.vue'),
    'product-list': () => import('@/components/place/ProductList.vue'),
    'near-schedule': () => import('@/components/reservation/NearSchedule.vue'),
    'thumbnail-detail-modal': () => import('@/pages/modal/ThumbnailDetailModal.vue'),
    'thumbnail-list-modal': () => import('@/pages/modal/ThumbnailListModal.vue'),
    'place-map-modal': () => import('@/pages/modal/PlaceMapModal.vue'),
    'reserve-modal': () => import('@/pages/modal/reservation/ReserveModal.vue'),
    'reserve-info-modal': () => import('@/pages/modal/reservation/ReserveInfoModal.vue'),
    'reserve-add-info-modal': () => import('@/pages/modal/reservation/ReserveAddInfoModal.vue'),
    'review-info': () => import('@/components/place/ReviewInfo.vue'),
    'like-share': () => import('@/components/common/LikeShare.vue'),
    'spinner': () => import('@/components/layout/Spinner.vue'),
  },
  metaInfo() {
    return {
      title: this.place.name + ' | POING',
      meta: [
        {
          name: 'keywords',
          vmid: 'keywords',
          content: '포잉, POING,' + this.place.name
        },
        {
          name: 'description',
          vmid: 'description',
          content: this.place.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: window.location.href
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.place.name + ' | POING'
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.place.description
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content: this.place.image && this.place.image.original.url
        }
      ]
    }
  }
})
export default class Place extends mixins(dataFormat) {
  @Prop()
  public placeId!: any;

  public isScroll: boolean = false;
  public isTabScroll: boolean = false;
  public visitedList: any = [];
  public loadingStatus: boolean = false;
  public selectLang: string = 'ko';
  public placeImage: any = placeModule.placeImage;
  public reserveParams: any = {
    date: moment().add(1, 'days').format('YYYY-MM-DD'),
    peopleCount: 2,
    peopleDetailCount: {},
    time: '',
    tableType: '',
    requestText: '',
    listBuyOption: [],
    calendarTableType: 'all',
    listTime: null,
    name: '',
    phone: '',
    email: '',
    countryCode: '',
    totalPrice: 0
  };
  public tab: any = 'home';
  public thumbTitle: any = this.place;
  public tabClick: boolean = false;
  public appBanner: boolean = false;
  public dataLoading: boolean = false;

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadingStart();
      vm.visitedCookies();
      vm.$modal.hide('placeMap');
      vm.appBannerUpdate();
    });
  }

  public beforeRouteLeave(to, from, next) {
    reservationModule.resetNearSchedule();
    reservationModule.resetCalendar();
    reservationModule.resetCapacity();
    next();
  }

  public eventViewItem(data) {
    const eventName = 'view_item';
    const eventParams: any = {
      type: 'place',
      id: data.id.toString(),
      name: data.name
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }

  public mounted() {
    this.loadingStart();
    this.dataLoading = true;

    placeModule.fetchPlaceInfoData(this.placeId).then(() => {
      this.loadingEnd();
      this.eventViewItem(this.place);
      if (this.place.reservation_method === 'option') {
        //예약 기준 날짜
        if (this.place.default_date_type === 'tomorrow') {
          this.reserveParams.date = moment().add('1', 'days').format('YYYY-MM-DD');
        } else if (this.place.default_date_type === 'today') {
          this.reserveParams.date = moment().add('0', 'days').format('YYYY-MM-DD');
        } else if (this.place.default_date_type === 'custom') {
          this.reserveParams.date = this.place.default_date;
        }
      }
    })
      .finally(() => {
        this.thumbTitle = this.place;
        this.dataLoading = false;
      });

    placeModule.fetchPlaceImage(this.placeId)
      .finally(() => {
        this.placeImage = placeModule.placeImage;
      });
    placeModule.fetchPlaceMenu(this.placeId);
    // placeModule.fetchPlaceProduct(this.placeId);
    this.initReview(this.placeId);
    EventBus.$on('thumbnailList', (data: any, item: any) => {
      this.placeImage = data;
      if (item && !item.name) {
        item.name = item.title ? item.title : item.name;
        this.thumbTitle = item;
      }
    });
    EventBus.$on('thumbnailListReset', () => {
      this.placeImage = placeModule.placeImage;
      this.thumbTitle = this.place;
    });
    EventBus.$on('PoingAppBannerClose', () => {
      this.appBanner = false;
    });
  }

  public beforeDestroy() {
    this.loadingEnd();
    document.addEventListener('scroll', this.handleScroll);
  }
  public loadingStart() {
    EventBus.$emit('loadingStatus', true);
    this.loadingStatus = true;
  }
  public loadingEnd() {
    EventBus.$emit('loadingStatus', false);
    this.loadingStatus = false;
  }

  public handleScroll() {
    if (this.tabClick) {
      setTimeout(() => {
        this.tabClick = false;
      }, 10);
      return;
    }
    const el = document.querySelector('.place-detail-wrap') as HTMLDivElement;
    const mainInfoEl: any = document.querySelector('.main-info-container') as HTMLDivElement || null;
    const noticeEl: any = document.querySelector('.place-notice-container') as HTMLDivElement || null;
    const placeInfoEl: any = document.querySelector('.place-info-container') as HTMLDivElement || null;
    const ticketEl: any = document.querySelector('.ticket-option-container') as HTMLDivElement || null;
    const menuEl: any = document.querySelector('.place-menu-container') as HTMLDivElement || null;
    const reviewEl: any = document.querySelector('.simple-review-section') as HTMLDivElement || null;
    if (el) {
      const bottom = el.scrollHeight;
      let barHeight = (window.innerWidth * 0.75) - 58;
      if (window.innerWidth > 600) {
        barHeight = 450;
      }
      let appBanner: any = 0;
      if (!this.$cookies.get('PoingAppBanner')) {
        if (window.innerWidth < 600) {
          appBanner = 68;
        }
      }
      const tab1Top: any = barHeight + (mainInfoEl ? mainInfoEl.clientHeight : 0) + (noticeEl ? noticeEl.clientHeight + 58 : 0);
      const tab2Top: any = tab1Top + (placeInfoEl ? placeInfoEl.clientHeight + 3 : 0);
      const tab3Top: any = tab2Top + (ticketEl ? ticketEl.clientHeight + 3 : 0);
      const tab4Top: any = tab3Top + (menuEl ? menuEl.clientHeight + 3 : 0);
      const tab5Top: any = tab4Top + (reviewEl ? reviewEl.clientHeight + 3 : 0);
      if (window.scrollY > barHeight) {
        if (window.scrollY > (bottom - window.innerHeight - 1)) {
          el.classList.add('scroll-bottom');
        } else {
          el.classList.remove('scroll-bottom');
        }
        if (window.scrollY > (barHeight + mainInfoEl.clientHeight)) {
          this.isTabScroll = true;
          if (window.scrollY > parseInt(String(tab1Top - appBanner))) {
            this.tab = 'home';
          }
          if (window.scrollY >= parseInt(String(tab2Top - appBanner))) {
            this.tab = 'ticket';
          }
          if (window.scrollY >= parseInt(String(tab3Top - appBanner))) {
            this.tab = 'menu';
          }
          if (window.scrollY >= parseInt(String(tab4Top - appBanner))) {
            this.tab = 'review';
          }
          if (window.scrollY >= parseInt(String(tab5Top - appBanner))) {
            this.tab = 'info';
          }
        } else {
          this.isTabScroll = false;
        }
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
      if (window.scrollY < (barHeight + (mainInfoEl ? mainInfoEl.clientHeight : 0))) {
        this.isTabScroll = false;
      }
    }
  }
  public tabMove(target: any) {
    this.appBannerUpdate();
    this.tabClick = true;
    const el = document.querySelector('.place-detail-wrap') as HTMLDivElement;
    const imgEl = document.querySelector('.detail-thumbnail-container') as HTMLDivElement;
    const mainInfoEl: any = document.querySelector('.main-info-container') as HTMLDivElement;
    const noticeEl: any = document.querySelector('.place-notice-container') as HTMLDivElement || null;
    const placeInfoEl: any = document.querySelector('.place-info-container') as HTMLDivElement || null;
    const ticketEl: any = document.querySelector('.ticket-option-container') as HTMLDivElement || null;
    const menuEl: any = document.querySelector('.place-menu-container') as HTMLDivElement || null;
    const reviewEl: any = document.querySelector('.simple-review-section') as HTMLDivElement || null;

    const tab1Top: any = imgEl.clientHeight + (mainInfoEl.clientHeight);
    const tab2Top: any = tab1Top + (placeInfoEl ? placeInfoEl.clientHeight + 3 : 0) + (noticeEl ? noticeEl.clientHeight + 58 : 0);
    const tab3Top: any = tab2Top + (ticketEl ? ticketEl.clientHeight + 3 : 0);
    const tab4Top: any = tab3Top + (menuEl ? menuEl.clientHeight + 3 : 0);
    const tab5Top: any = tab4Top + (reviewEl ? reviewEl.clientHeight + 3 : 0);
    let appBanner: boolean = true;
    if (this.$cookies.get('PoingAppBanner') === 'Y') {
      appBanner = false;
    }
    el.classList.add('scroll-bottom');
    this.isScroll = true;
    this.isTabScroll = true;
    if (target === 'tab1') {
      if (window.innerWidth > 600) {
        window.scrollTo(0, tab1Top);
        return;
      }
      window.scrollTo(0, tab1Top - 58);
    } else if (target === 'tab2') {
      if (window.innerWidth > 600) {
        window.scrollTo(0, tab2Top);
        return;
      }
      window.scrollTo(0, tab2Top - 58);
    } else if (target === 'tab3') {
      if (window.innerWidth > 600) {
        window.scrollTo(0, tab3Top);
        return;
      }
      window.scrollTo(0, tab3Top - 58);
    } else if (target === 'tab4') {
      if (window.innerWidth > 600) {
        window.scrollTo(0, tab4Top);
        return;
      }
      window.scrollTo(0, tab4Top - 58);
    } else if (target === 'tab5') {
      if (window.innerWidth > 600) {
        window.scrollTo(0, tab5Top);
        return;
      }
      window.scrollTo(0, tab5Top - 58);
    }
  }

  public created() {
    document.addEventListener('scroll', this.handleScroll);
  }

  public visitedCookies() {
    const now = new Date().getTime();
    const after1Month = now + 86400000 * 30;
    if (this.$cookies.get('poingVisited')) {
      this.visitedList = JSON.parse(this.$cookies.get('poingVisited'));
      this.visitedList = filter(this.visitedList, (i) =>
        i.place_id !== this.place.id && i.visited_at > now
      )
    }
    this.visitedList.unshift({
      place_id: this.place.id,
      visited_at: after1Month
    });
    const visited = JSON.stringify(this.visitedList);
    this.$cookies.set('poingVisited', visited);
  }

  public back() {
    if (window.history.length === 1) {
      this.$router.push({path: '/'});
    } else {
      this.$router.go(-1);
    }
  }

  public customLink() {
    if (this.place.custom_button.type === 'ticket') this.$router.push({ path: this.place.custom_button.scheme_web });
    else window.open(this.place.custom_button.scheme_web, '_blank');
  }
  public todayUpdate() {
    this.reserveParams.date = moment().format('YYYY-MM-DD');
  }
  public initReview(id) {
    const params = {
      type: 'recommend',
      offset: 1,
      limit: 3,
      place_id: id,
      time: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    reviewModule.fetchUserReview(params);
  }
  public appBannerUpdate() {
    if (this.$cookies.get('PoingAppBanner') === 'Y') {
      return this.appBanner = false;
    }
    return this.appBanner = true;
  }
  public menuClick(index: any) {
    this.$router.push({
      name: 'placeMenu',
      params: { placeId: this.place.id},
      query: {
        tab: index
      }
    });
  }

  get isCustomPlace(): any {
    return Number(this.placeId) === 51034 ? true : false;
  }

  get place(): any {
    return placeModule.placeInfoData;
  }
  // get placeImage() {
  //   return placeModule.placeImage;
  // }
  get placeMenu() {
    return placeModule.placeMenu;
  }
  get productList() {
    return placeModule.placeProduct;
  }
  get reviews() {
    return reviewModule.userReview;
  }
}
